import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Tour: Dashbot for Customer Service`}</h2>
    <h2>{`Optimize Response Effectiveness`}</h2>
    <p>{`With Dashbot’s Phrase Clustering, you can identify where the chatbot may be breaking down through mishandled or unhandled Intents.`}</p>
    <p>{`The information can be used to train your NLP engine to improve response effectiveness and user satisfaction.`}</p>
    <h3>{`Identify mishandled and unhandled Intents`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/Gn86aDSWD9k?feature=oembed" width="1200" />
    <h3>{`Reduce failure rate`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/e4IGl7d-49Q?feature=oembed" width="1200" />
    <h2>{`Reduce escalations / Increase containment`}</h2>
    <p>{`With Dashbot’s Escalation Report, you can quickly monitor escalations, or containment, to see the percentages overtime, as well as the common paths that lead to escalations, and the transcripts in which they occur.`}</p>
    <p>{`Dive deeper into the flows that lead to escalations, to get a better understanding of user behavior, where the chatbot may be breaking down, and improve the overall user experience.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/BZwl65zf2Ck" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Get real-time notifications to insert live agents`}</h2>
    <p>{`Get real-time alerts of sessions going awry, and insert a live agent to help lead users to successful outcomes.`}</p>
    <p>{`Dashbot APIs integrate with 3rd party live agent, chat platforms, including Genesys.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/BV4F1X-WoVI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Transcripts`}</h2>
    <p>{`View users previous conversations or dive deeper into a conversation to better understand the context.`}</p>
    <p>{`In addition to top messages, utterances, and intents, we reconstruct all the session transcripts – including live sessions which can be viewed in real time!`}</p>
    <p>{`We support the full richness and multi-modal nature of platforms. Messages in and out can be rich media: images, animated gifs, audio, video, GPS coordinates, cards, lists, and more.`}</p>
    <p>{`Dashbot also shows the underlying NLP Intents and Entities.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/04162216/transcript-fb-movie.png",
        "alt": null
      }}></img></p>
    <h3>{`Transcript Search`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/03113350/transcript-search.gif",
        "alt": null
      }}></img></p>
    <h2>{`Anomalous Sessions`}</h2>
    <p>{`Dashbot can detect anomalous sessions – outliers from typical conversions that may warrant further review.`}</p>
    <p>{`Quickly identify outliers and dive deeper into the conversations to see where users may be having difficulty.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/03111930/outlier-transcripts-1024x486.png",
        "alt": null
      }}></img></p>
    <h2>{`Build a chatbot from live transcripts`}</h2>
    <hr></hr>
    <p>{`Are you looking to build a chatbot? Do you have historical live agent transcripts?`}</p>
    <p>{`Dashbot’s Phrase Clustering can process live agent transcripts to identify common user messages and Intents as well as agent responses to kick start chatbot development. The data can be used to train your NLP engine.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/02/16181540/message-processing-600x467.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      